
.overlay-2 {
    opacity: 0.8;
    object-fit: cover; /* object-cover */
    margin-left: auto; /* mx-auto (horizontal centering) */
    margin-right: auto; /* mx-auto (horizontal centering) */
    filter: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.3)); /* drop-shadow-2xl */
    mask-image: linear-gradient(to bottom, white 50%, transparent 100%);
    /*--tw-blur: blur(50px);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) ;*/
}


/* When screen width is greater than screen height */
@media (min-aspect-ratio: 1/1) {
    .overlay-2 {
        width: 100%;
        height: auto; /* Maintain aspect ratio */
    }
}

/* When screen height is greater than screen width */
@media (max-aspect-ratio: 1.5/1) {
    .overlay-2 {
        width: auto; /* Maintain aspect ratio */
        height: 100vh;
    }
}

.overlay-1 {
    opacity: 0.8;
    box-shadow: 0 0 200px rgba(0,0,0,0.7) inset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140vh;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, white 75%, transparent);
}


.overlay-1::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
        linear-gradient(217deg, rgba(255, 32, 61, 0.7), rgba(255, 32, 61, 0) 70.71%),
        linear-gradient(127deg, rgba(93, 163, 255, 0.7), rgba(93, 163, 255, 0) 70.71%),
        linear-gradient(336deg, rgba(255, 32, 61, 0.7), rgba(255, 32, 61, 0) 70.71%);
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
    
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/*


.overlay-2 {
    opacity: 1;
    object-fit: cover; // object-cover
    margin-left: auto; // mx-auto (horizontal centering)
    margin-right: auto; // mx-auto (horizontal centering)
    filter: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.3)); // drop-shadow-2xl
    mask-image: linear-gradient(to bottom, white 50%, transparent 100%);
   
    //--tw-blur: blur(50px);
    //filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
...


@media (min-aspect-ratio: 1/1) {
    .overlay-2 {
        width: 100vw;
        height: auto;
    }
}

@media (max-aspect-ratio: 1.5/1) {
    .overlay-2 {
        width: auto;
        height: 100vh;
    }
}

.overlay-1 {
    box-shadow: 0 0 200px rgba(0,0,0,0.7) inset;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 140vh;
    overflow: hidden;
    mask-image: linear-gradient(to bottom, white 75%, transparent);
}


.overlay-1::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: 
        linear-gradient(217deg, rgba(255, 32, 61, 0.8), rgba(255, 32, 61, 0) 70.71%),
        linear-gradient(127deg, rgba(0, 0, 255, 0.8), rgba(0, 0, 255, 0) 70.71%),
        linear-gradient(336deg, rgba(255, 32, 61, 0.8), rgba(255, 32, 61, 0) 70.71%);
    background-size: 400% 400%;
    animation: gradientMove 15s ease infinite;
}

@keyframes gradientMove {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
*/
